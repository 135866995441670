import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { AnimateOnChange } from 'react-animation';


const WELCOME = ['Welcome', 'To', 'The', 'Fucking', 'Show'];
class Fadeto extends Component {
  constructor(props) {
    // Must have a content props
    super(props);
    this.state = { count: 0 };
  }
  #isMounted = null;

  componentDidMount() {
    this.#isMounted = true;
    this.timerID = setInterval(() => this.updateTimer(), 5000);
    // ReactDOM.render(<span>{this.state.count}</span>, document.getElementById('fadeToRoot'));
  }

  componentWillUnmount() {
    this.#isMounted = false;
  }

  updateTimer() {
    if (this.#isMounted) {
      // ReactDOM.render(<span>{this.state.count}</span>, document.getElementById('fadeToRoot'));
      this.setState((state, props) => ({
        count: state.count < props.content.length - 1 ? state.count + 1 : 0
      }));
    }
  }
  render() {
    return (
      <AnimateOnChange animation="fade" durationOut="1000" >
        {this.props.content[this.state.count]}
      </AnimateOnChange>
    )
  }
}

export default Fadeto;