import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';

import "./Tablet.css";

const Tablet = (props) => {
  const { imageSrc, pros, cons, title, price, consensus } = props;
  return (
    <div className="tablet-card-container" style={props.style}>
      <h2>{title}</h2>
      <img src={imageSrc} alt='A tablet'/>
      <h3>Pros</h3>
      <ul>
        {Array.isArray(pros) && pros.map((value) => <div className='tablet-item'>
      <FontAwesomeIcon icon={faPlusCircle} className="tablet-icon-plus"/><li>{value}</li></div>)}
      </ul>
      <h3>Cons</h3>
      <ul>
        {Array.isArray(cons) && cons.map((value) => <div className='tablet-item'><FontAwesomeIcon icon={faMinusCircle} className="tablet-icon-minus"/><li>{value}</li></div>)}
      </ul>
      <p className='tablet-card-consensus'>{consensus}</p>
      <h3>{price}</h3>
    </div>
  )
}

export default Tablet;