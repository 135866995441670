import React from 'react';

import Layout from '../../hoc/Layout/Layout';
import Content from '../../components/Content/Content';
import './Resume.css';

const Resume = (props) => {
    return (
        <Layout showFooter={false}>
            <html>
                <body>
                    <div class="container">
                        <div class="header">
                            <div class="header-container">
                                <div class="left-container">
                                    <h1><span class="semi-bold">Peter</span><span class="reg accent-text"> Palumbo</span></h1>
                                    <h5><span class="reg primary">Software Engineer</span></h5>
                                </div>
                                <div class="right-container">
                                    <table class="social-media">
                                        {/* <tr>
                                            <td class="right">peter.f.palumbo@gmail.com</td>
                                            <td class="center"><i class="fas fa-envelope primary"></i> </td>
                                        </tr> */}
                                        {/* <tr>
                                            <td class="right">(518) - 860 - 4946</td>
                                            <td class="center"><i class="fas fa-mobile-alt primary"></i> </td>
                                        </tr> */}
                                        <tr>
                                            <td class="right">Saratoga Springs, NY</td>
                                            <td class="center"><i class="fas fa-map-marker-alt primary"></i></td>
                                        </tr>
                                        <tr>
                                            <td class="right">peter-fm-palumbo</td>
                                            <td class="center"><i class="fab fa-linkedin-in primary"></i></td>
                                        </tr>
                                        <tr>
                                            <td class="right">retep.dev</td>
                                            <td class="center"><i class="fas fa-globe primary"></i></td>
                                        </tr>
                                        <tr>
                                            <td class="right">ppalumbo17</td>
                                            <td class="center"><i class="fab fa-github primary"></i></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="main">
                            <div class="main-container">
                                <div class="main-left">
                                    <div class="main-section">
                                        <div class="main-section-header">
                                            <i class="fas fa-user primary"></i>
                                            <span class="semi-bold">Summary</span>
                                        </div>
                                        <div class="main-section-content">
                                            <p>With over seven years of enterprise software development, and fifteen years of programming experience, I am
                                                looking for a position where I can grow as a developer, while contributing to a worthwhile project that
                                                I
                                                have a share of ownership in.</p>
                                        </div>
                                    </div>
                                    {/* <!-- Work Experience --> */}
                                    <div class="main-section">
                                        <div class="main-section-header">
                                            <i class="fas fa-briefcase primary"></i>
                                            <span class="semi-bold">Professional Experience</span>
                                        </div>
                                        <div class="main-section-content">
                                            {/* <!-- Ridgeline--> */}
                                            <div class="main-section-content-flex">
                                                <div class="main-section-content-flex-left">
                                                    <span>Jan 2021 - May 2024</span>
                                                </div>
                                                <div class="main-section-content-flex-right">
                                                    <h6 class="primary">Platform Engineer</h6>
                                                    <h6 class="semi-bold">Ridgeline Apps</h6>
                                                    <ul>
                                                        <li>
                                                            Worked on our core UI team creating in house components for our product teams using React, Typescript, SASS.
                                                        </li>
                                                        <li>
                                                            Alongside UX designers, created the basis for an in house design system.
                                                            This system focused on the close interactions between the as designed Figma components, and implementation.
                                                            The key focuses were on accessibility, analogous patterns, cohesive user experience, and succinct testability.
                                                        </li>
                                                        <li>
                                                            Closely supported product teams to build out complex financial pages using React, Typescript, GraphQL, and AWS services.
                                                        </li>
                                                        <li>
                                                            Curated a culture of robust documentation to speed along the process at which questions were answered.
                                                            Recently helped create a github action workflow that automatically updates documentation with AI based on the contents of a pull request.
                                                        </li>
                                                        <li>
                                                            Created a library of cypress test helpers that allowed for fast and easy spin up of e2e or integration tests.
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* <!-- Retepid --> */}
                                            <div class="main-section-content-flex">
                                                <div class="main-section-content-flex-left">
                                                    <span>Jan 2020 - Jan 2021</span>
                                                </div>
                                                <div class="main-section-content-flex-right">
                                                    <h6 class="primary">Lead Software Engineer</h6>
                                                    <h6 class="semi-bold">Retepid LLC</h6>
                                                    <ul>
                                                        <li>
                                                            Lead the development of a travel based app built using React Native Typescript, AWS Lambda, AWS
                                                            S3, AWS
                                                            DynamoDB, and AWS API Gateway
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* <!-- Nextworld --> */}
                                            <div class="main-section-content-flex">
                                                <div class="main-section-content-flex-left">
                                                    <span>Nov 2018 - Jan 2020</span>
                                                </div>
                                                <div class="main-section-content-flex-right">
                                                    <h6 class="primary">Software Engineer</h6>
                                                    <h6 class="semi-bold">Nextworld LLC</h6>
                                                    <ul>
                                                        <li>
                                                            Created forward facing components using Angular 7/8 Typescript, Jtwig, Protractor, and Karma
                                                        </li>
                                                        <li>Built an iCalendar standard component allowing for multiple recurrence events maintained from a
                                                            RESTful API, a crucial requirement for a potential client</li>
                                                        <li>Re-designed a pub/sub model for distributing metadata values throughout our tool</li>
                                                        <li>
                                                            Designed a full dashboard with ingrained navigation and status cards as well as a modular
                                                            dashboard menu that saved users 4 clicks per route navigation
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="main-section-content-flex">
                                                <div class="main-section-content-flex-left">
                                                    <span>Jun 2017 - Nov 2018</span>
                                                </div>
                                                <div class="main-section-content-flex-right">
                                                    <h6 class="primary">Associate Software Engineer</h6>
                                                    <h6 class="semi-bold">Nextworld LLC</h6>
                                                    <ul>
                                                        <li>
                                                            Architected a no code testing environment built on top of the protractor and selenium testing
                                                            frameworks
                                                        </li>
                                                        <li>Developed a full stack environment for a brand-new, patent pending, system that allows
                                                            non-developer users to create end to end tests for enterprise applications</li>
                                                        <li>
                                                            This tool has been described as both "intuitive" and "easy to use" having found 130 new bugs in
                                                            its first three months of use
                                                        </li>
                                                        <li>The tool continues to be used for internal testing and is at the forefront of product sales</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="main-section-content-flex">
                                                <div class="main-section-content-flex-left">
                                                    <span>Jan 2017 - May 2017</span>
                                                </div>
                                                <div class="main-section-content-flex-right">
                                                    <h6 class="primary">Software Engineer Intern</h6>
                                                    <h6 class="semi-bold">Nextworld LLC</h6>
                                                    <ul>
                                                        <li>
                                                            Using RxJS patterns, laid the groundwork for global user settings to persist through login
                                                        </li>
                                                        <li>
                                                            Implemented draggable data tables to create smoother transitions for users working with multiple
                                                            data sets
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- END Experience--> */}
                                    {/* <!-- Education --> */}
                                    <div class="main-section">
                                        <div class="main-section-header">
                                            <i class="fas fa-graduation-cap primary"></i>
                                            <span class="semi-bold">Education</span>
                                        </div>
                                        <div class="main-section-content">
                                            {/* <!-- Retepid --> */}
                                            <div class="main-section-content-flex">
                                                <div class="main-section-content-flex-left">
                                                    <span>Aug 2013 - May 2017</span>
                                                </div>
                                                <div class="main-section-content-flex-right">
                                                    <h6 class="primary">Bachelor of Science, Computer Science</h6>
                                                    <h6 class="semi-bold">Colorado School of Mines</h6>
                                                    <p>President's Scholar</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- END Education--> */}
                                    {/* <!-- Personal Projects --> */}
                                    <div class="main-section">
                                        <div class="main-section-header">
                                            <i class="fas fa-chart-pie primary"></i>
                                            <span class="semi-bold">Recent Personal Projects</span>
                                        </div>
                                        <div class="main-section-content">
                                            {/* <!-- Retepid --> */}
                                            <div class="main-section-content-flex">
                                                <div class="main-section-content-flex-left">
                                                    <span>Oct 2020 - Jan 2021</span>
                                                </div>
                                                <div class="main-section-content-flex-right">
                                                    <h6 class="primary">Random Daily Workout</h6>
                                                    <p>Tired of doing the same five workout routines every day, I decided to work on a randomly
                                                        generated workout plan that still focuses on an appropriate group of muscles. Designed
                                                        the
                                                        serverless backend to be implemented on AWS while working on a front end in React Native
                                                        TypeScript. Planning to resuscitate with AI integration soon.</p>
                                                </div>
                                            </div>
                                            <div class="main-section-content-flex">
                                                <div class="main-section-content-flex-left">
                                                    <span>Oct 2020</span>
                                                </div>
                                                <div class="main-section-content-flex-right">
                                                    <h6 class="primary">Digital Scorekeeper</h6>
                                                    <p>A score keeping app built with React Native/Expo and Firebase REST APIs. Built primarily for use
                                                        in card games with the ability to see historical data between groups of friends.</p>
                                                </div>
                                            </div>
                                            <div class="main-section-content-flex">
                                                <div class="main-section-content-flex-left">
                                                    <span>Sep 2020</span>
                                                </div>
                                                <div class="main-section-content-flex-right">
                                                    <h6 class="primary">Resume Builder</h6>
                                                    <p>You're looking at it! A resume creator tool built with Vanilla JS, HTML5, and CSS3. The goal was
                                                        to have something easily editable that got away from the boring resumes of the past.</p>
                                                </div>
                                            </div>
                                            <div class="main-section-content-flex">
                                                <div class="main-section-content-flex-left">
                                                    <span>Jan 2020 - Aug 2020</span>
                                                </div>
                                                <div class="main-section-content-flex-right">
                                                    <h6 class="primary">Togetherness</h6>
                                                    <p>A group todo list and project tracker for collaborative planning. Built using React Native and
                                                        Firebase REST APIs. Fully functional with user authentication, group creation, and qr code
                                                        scanning. Is currently being used as both a family grocery list and personal agile tracker.
                                                        Pending approval upon review for the app store.</p>
                                                </div>
                                            </div>
                                            <div class="main-section-content-flex">
                                                <div class="main-section-content-flex-left">
                                                    <span>Sep 2019 - Dec 2019</span>
                                                </div>
                                                <div class="main-section-content-flex-right">
                                                    <h6 class="primary">Financial Dashboard</h6>
                                                    <p>Personalized financial dashboard to keep track of spending, debts, investment, and net worth
                                                        using Svelte 3, Rollup, Google Charts, and other finance APIs.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- END Personal Projects --> */}
                                </div>
                                <div class="main-right">
                                    {/* <!-- Achievements--> */}
                                    <div class="main-section">
                                        <div class="main-section-header">
                                            <i class="fas fa-award primary"></i>
                                            <span class="semi-bold">Achievements</span>
                                        </div>
                                        <div class="main-section-content">
                                            <h6 class="primary">Ridgeline Apps</h6>
                                            <ul>
                                                <li>Lead many "fireside chats" on how CSS can be used in place of JavaScript</li>
                                                <li>
                                                    Created more than 50% of the actively used components including Navigation, Dialog/Modals, Popovers, KPIs, Sidepanels, Forms w/ various editors, and more.
                                                </li>
                                                <li>
                                                    Lead a task force to create a new interview process and coding exercise for new grad hires.
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="main-section-content">
                                            <h6 class="primary">Nextworld LLC</h6>
                                            <ul>
                                                <li>
                                                    Designed a patent pending tool for no code testing that is now one of the leading selling points of
                                                    the company product
                                                </li>
                                                <li>
                                                    Developed the landing page dashboard that is seen every time the tool is used
                                                </li>
                                                <li>
                                                    Lead recruiting for college hires
                                                </li>
                                                <li>
                                                    Co-lead a team of four developers within my first year
                                                </li>
                                                <li>
                                                    Hosted company wide presentations on the benefits of testing
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <!-- END Achievements --> */}
                                    {/* <!-- Lessons Learned --> */}
                                    <div class="main-section">
                                        <div class="main-section-header">
                                            <i class="fas fa-book primary"></i>
                                            <span class="semi-bold">Lessons Learned</span>
                                        </div>
                                        <div class="main-section-content">
                                            <h6 class="primary">Ridgeline Apps</h6>
                                            <ul>
                                                <li>
                                                    Extendible, reuseable components are crucial to create a uniform look and feel throughout a complex app
                                                </li>
                                                <li>
                                                    When actively working with the consumers of built components, documentation and developer experience are arguably the most important focus
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="main-section-content">
                                            <h6 class="primary">Nextworld LLC</h6>
                                            <ul>
                                                <li>
                                                    Proper test driven development creates more robust, longer lasting, and more rapidly produced
                                                    products than a standard “quick ship” approach
                                                </li>
                                                <li>
                                                    Using libraries can save crucial time, but it is important to pick the right ones in order to avoid
                                                    unnecessary bloat
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <!-- END Lessons Learned --> */}
                                    {/* <!-- Main Skills --> */}
                                    <div class="main-section">
                                        <div class="main-section-header">
                                            <i class="fas fa-rocket primary"></i>
                                            <span class="semi-bold">Top Skills</span>
                                        </div>
                                        <div class="main-section-content">
                                            <ul class="skills">
                                                <li>
                                                    <span>JavaScript</span>
                                                    <div class="skills-right">
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span>HTML5</span>
                                                    <div class="skills-right">
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span>Java</span>
                                                    <div class="skills-right">
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span>TypeScript</span>
                                                    <div class="skills-right">
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake accent"></i>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span>CSS3</span>
                                                    <div class="skills-right">
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake accent"></i>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span>React</span>
                                                    <div class="skills-right">
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake accent"></i>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span>Angular</span>
                                                    <div class="skills-right">
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake accent"></i>
                                                        <i class="fas fa-snowflake accent"></i>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span>AWS</span>
                                                    <div class="skills-right">
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake accent"></i>
                                                        <i class="fas fa-snowflake accent"></i>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span>React Native</span>
                                                    <div class="skills-right">
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake primary"></i>
                                                        <i class="fas fa-snowflake accent"></i>
                                                        <i class="fas fa-snowflake accent"></i>
                                                        <i class="fas fa-snowflake accent"></i>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <!-- END Main Skills -->
          <!-- Experience --> */}
                                    <div class="main-section">
                                        <div class="main-section-header">
                                            <i class="fas fa-globe-americas primary"></i>
                                            <span class="semi-bold">Experience With</span>
                                        </div>
                                        <div class="main-section-content">
                                            <div class="extra-skills">
                                                <span>Agile</span>
                                                {/* <!-- <span>Bash</span>
                <span>Bitbucket</span>
                <span>Blender</span> --> */}
                                                <span>C++</span>
                                                {/* <!-- <span>Command Line</span>
                <span>Chart.js</span>
                <span>DevTools</span> --> */}
                                                <span>Django</span>
                                                {/* <!-- <span>Docker</span>
                <span>EJS</span>
                <span>Enzyme</span>
                <span>Firebase</span>
                <span>Gimp</span> --> */}
                                                <span>Git</span>
                                                {/* <!-- <span>GitHub</span> --> */}
                                                <span>Go</span>
                                                {/* <!-- <span>Google Charts</span> --> */}
                                                <span>GraphQL</span>
                                                {/* <!-- <span>Jasmine</span>
                <span>Jest</span>
                <span>Jenkins</span>
                <span>Karma</span>
                <span>Laravel</span>
                <span>Materialize</span> --> */}
                                                <span>Node</span>
                                                <span>NoSQL</span>
                                                <span>Npm</span>
                                                <span>PostgreSQL</span>
                                                {/* <!-- <span>Postman</span>
                <span>Protractor</span> --> */}
                                                <span>Python</span>
                                                {/* <!-- <span>Rollup</span> --> */}
                                                <span>Ruby</span>
                                                {/* <!-- <span>RxJS</span> */}
                                                <span>Sass</span>
                                                <span>Serverless</span>
                                                <span>Svelte</span>
                                                {/* <!-- <span>Visual Studio</span>
                <span>Vim</span>
                <span>Webpack</span>
                <span>Zsh</span> --> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- END Experience --> */}
                                    {/* <!-- Begin Hobbies --> */}
                                    <div class="main-section">
                                        <div class="main-section-header">
                                            <i class="fas fa-globe-americas primary"></i>
                                            <span class="semi-bold">Hobbies</span>
                                        </div>
                                        <div class="main-section-content-grid">
                                            <div class="hobby-container center">
                                                <h2><i class="fas fa-skiing primary"></i></h2>
                                                <p>Skiing</p>
                                            </div>
                                            <div class="hobby-container center">
                                                <h2><i class="fas fa-camera-retro primary"></i></h2>
                                                <p>Photography</p>
                                            </div>

                                            <div class="hobby-container center">
                                                <h2><i class="fas fa-passport primary"></i></h2>
                                                <p>Travel</p>
                                            </div>
                                            <div class="hobby-container center">
                                                <h2><i class="fas fa-hiking primary"></i></h2>
                                                <p>Hiking</p>
                                            </div>
                                            <div class="hobby-container center">
                                                <h2><i class="fab fa-unity primary"></i></h2>
                                                <p>3D Design</p>
                                            </div>
                                            <div class="hobby-container center">
                                                <h2><i class="fas fa-pencil-ruler primary"></i></h2>
                                                <p>Woodwork</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- END Hobbies -->
          <!-- BEGIN Past Life --> */}
                                    <div class="main-section">
                                        <div class="main-section-header">
                                            <i class="fas fa-globe-americas primary"></i>
                                            <span class="semi-bold">Past Life</span>
                                        </div>
                                        <div class="main-section-content">
                                            <h6 class="primary">Beta Theta Pi</h6>
                                            <ul>
                                                <li>
                                                    Advisor
                                                </li>
                                                <li>
                                                    President
                                                </li>
                                                <li>
                                                    Treasurer
                                                </li>
                                                <li>
                                                    Recruitment Chair
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="main-section-content">
                                            <h6 class="primary">Take Back the Night</h6>
                                            <ul>
                                                <li>
                                                    Helped plan campus event to bring attention to sexual assualt and domestic violence
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="main-section-content">
                                            <h6 class="primary">USASA</h6>
                                            <ul>
                                                <li>
                                                    2nd Place National Freestyle Skiing Championships
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <!-- END Past Life --> */}
                                </div>
                            </div>
                        </div>
                        <div class="footer">
                            <p class="hidden">This is hidden text to trick resume readers</p></div>
                    </div>
                </body>
            </html>
        </Layout>
    )
}

export default Resume;