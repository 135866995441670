import React, {useEffect, useState} from 'react';
import { useMediaQuery } from '@react-hook/media-query';

import Content from '../../components/Content/Content';
import TwoColumn from '../../components/Sections/Flex/Flex/TwoColumn/TwoColumnFlex';
import Card from '../../components/Cards/Standard/StandardCard';
import StandardButton from '../../components/Buttons/StandardButton/Standard';


import './Project.css';

const Projects = (props) => {
  let dummyData = [
    {title: 'Togetherness', image: 'https://images.unsplash.com/photo-1593642532842-98d0fd5ebc1a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80', description: 'Derived from a need for a joint todo list for my wife and I to get tasks done collectively, I created such an app using React Native. '},
    {title: 'Title 2', image: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80', description: 'A description 2'},
    {title: 'Title 3', image: 'https://images.unsplash.com/photo-1586281380349-632531db7ed4?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mzd8fHByb2plY3R8ZW58MHx8MHw%3D&auto=format&fit=crop&w=600&q=60', description: 'A description 3'},
    {title: 'Title 4', image: 'https://firebasestorage.googleapis.com/v0/b/retep-dev.appspot.com/o/SternPose.jpg?alt=media&token=3bf11a3a-93ae-49d0-8b99-e97e0d3c100d', description: 'A description 4'},
  ];

  const cardStyle = {
    'flexDirection': 'column',
    padding: '1rem'
  };

  const mediaMatches = useMediaQuery('only screen and (min-width: 992px)');
  const [projects, setProjects] = useState([]);
  const [showPopup, setShowPopUp] = useState(false);

  useEffect(() => {
    const loadData = async () => {
    const response = await fetch('https://retep-dev.firebaseio.com/projects.json');
    const resData = await response.json();
    console.log('Projecxts', resData);
    let projectData = [];
    for (const key in resData) {
      projectData.push(resData[key]);
    }
    setProjects(projectData);
  }
  loadData();
}, []);

  const projectList = () => {
    dummyData.map((d, i) => <div><p>d</p></div>);
  }

  const logData = (data) => {
    console.log('Data', data);
  }

  const renderProject = (data, index) => {
    console.log('Rendering project', index);
    const fd = index % 2 === 0 ? 'row' : 'row-reverse';
    const rowStyle = {
      height: '51vh',
      'flexDirection': mediaMatches ? index % 2 === 0 ? 'row' : 'row-reverse' : 'column',
      padding: '2vh 4vw'
    }

    const handleLearnMore = () => {
      if (data.link) {
        window.open(data.link, "_blank");
      } else {
        setShowPopUp(true);
      }
    }
    // return (
    //   <p>text</p> 
    // )
    return (
      <TwoColumn style={rowStyle} key={index}>
        <Card>
          {/* <img src={data.image} /> */}
          <img src={data.image} />
        </Card>
        <div className="Project-description">
          <h3>{data.title}</h3>
          <p>{data.description}</p>
          <StandardButton onclick={handleLearnMore} text="Learn More" />
        </div>
      </TwoColumn>
    );
  }

  return (
    <div id="ProjectsContainer">
      <Content>
        {projects.map((d, i) => renderProject(d, i))}
        {showPopup && 
        <div className="Overlay" onClick={() => setShowPopUp(false)}>
        <div className="PopUp-Overlay">
        <Card style={cardStyle}>
          <p>Sorry, this project does not have it's own website yet.</p>
          <StandardButton onclick={() => setShowPopUp(false)} text="Check Back Soon" />
        </Card>
          </div>
        </div>}
      {/* <TwoColumn style={{height: '51vh'}}>
          <Card>Hello, it's me</Card>
          <p>HELLO FROM the other side</p>
      </TwoColumn> */}
      </Content>
    </div>
  )
}

export default Projects;