import React from 'react';

import './Standard.css';

const StandardButton = (props) => {
  return (
    <button style={props.style} type={props.type} className="Standard-button gradient-button" onClick={props.onclick}>{props.text}</button>
  )
}

export default StandardButton;