import React from 'react';

import "./StandardCard.css";

const StandardCard = (props) => {
  return (
    <div className="Card-container" style={props.style}>
      {props.children}
    </div>
  )
}

export default StandardCard;