import React, { useState } from 'react';
import {NavLink} from 'react-router-dom';
import { useMediaQuery } from '@react-hook/media-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import {ReactComponent as Logo } from '../../assets/pictures/prizmaticlogo.svg';
import './Nav.css';

const Nav = ({ sticky }) => {
  const [showMenu, setShowMenu] = useState(false);
  const mediaMatches = useMediaQuery('only screen and (max-width: 992px)');
  // const [isSticky, setIsSticky] = useState(false);

  const handleMenuShow = () => {
    setShowMenu(!showMenu);
  }

  const NavMenuItems = () => {
    return (
      <div className="Nav-menu-items">
      <NavLink exact to="/" activeClassName="Nav-item-active" className="Nav-item" onClick={() => setShowMenu(false)}>Home</NavLink>
       <NavLink to="/resume" activeClassName="Nav-item-active" className="Nav-item" onClick={() => setShowMenu(false)}>Resume</NavLink>
       {/* <NavLink to="/about" activeClassName="Nav-item-active" className="Nav-item" onClick={() => setShowMenu(false)}>About</NavLink>
       <NavLink to="/projects" activeClassName="Nav-item-active" className="Nav-item" onClick={() => setShowMenu(false)}>Projects</NavLink>
       <NavLink to="/gallery" activeClassName="Nav-item-active" className="Nav-item" onClick={() => setShowMenu(false)}>Photos</NavLink>
       <NavLink to="/merrychristmasmackie" activeClassName="Nav-item-active" className="Nav-item" onClick={() => setShowMenu(false)}>Merry X-Mas Mack</NavLink> */}
       </div>
    )
  }

 return (
   <div className={sticky ? "Nav-container Nav-container-sticky" : "Nav-container"}>
     <div className="Nav-left-container">
       <div className="Nav-left-logo-container">
       <Logo style={{height: '100%'}}/>
       </div>
     </div>
     <div className="Nav-right-container regular">
       {!mediaMatches && <NavMenuItems />}
       {mediaMatches && !showMenu && <FontAwesomeIcon icon={faBars} onClick={handleMenuShow}/>}
     </div>
     {mediaMatches && showMenu &&
    <div className="Nav-menu-overlay">
      <FontAwesomeIcon icon={faTimes} className="Nav-menu-times" onClick={handleMenuShow}/>
      <NavMenuItems />
    </div>
    }
    </div>
 );
}

export default Nav;