import React from 'react';

import Content from '../../components/Content/Content';

const About = (props) => {
  return (
    <div id="AboutContainer">
      <Content>
      <p>About Container</p>
      </Content>
    </div>
  )
}

export default About;