import React, {useState} from 'react';

import './Footer.css';

const Footer = (props) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const handleContactForm = (event) => {
    event.preventDefault();
    console.log('Sending email with email', email, 'name', name, 'and message', message);
  }

  return (
    <div className="Footer-container">
      {/* Put contact form and links to github, linkedin, resume, twitter whatever */}
      <div className="Footer-content">
        <div className="Footer-form">
          <form id="contact-form" onSubmit={handleContactForm}>
            <div className="Footer-form-group">
              <label htmlFor="name" className="gradient-text">Name</label>
              <input className="Footer-form-control" type="text" id="name" value={name} onChange={(event) => setName(event.target.value)} />
            </div>
            <div className="Footer-form-group">
              <label htmlFor="email" className="gradient-text">Email</label>
              <input className="Footer-form-control" type="email" id="email" value={email} onChange={(event) => setEmail(event.target.value)}/>
            </div>
            <div className="Footer-form-group">
              <label htmlFor="message" className="gradient-text">Message</label>
              <textarea rows="5" id="message" value={message} onChange={(event) => setMessage(event.target.value)}></textarea>
            </div>
            <div className="Footer-form-group">
            <button type="submit" className="Footer-form-submit gradient-button">Send</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Footer;