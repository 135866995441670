import React from 'react';
import {
  Switch,
  Route,
  BrowserRouter
} from 'react-router-dom';
import logo from './logo.svg';

// Imports
import Layout from './hoc/Layout/Layout';
import Main from './containers/Main/Main';
import About from './containers/About/About';
import Projects from './containers/Projects/Projects';
import Mackie from './containers/Mackie/MackieFn';
import './App.css';
import Resume from './containers/Resume/Resume';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path='/merrychristmasmackie' component={Mackie} />
        <Route exact path='/resume' component={Resume} />
        <Route>
          <div className="App">

            <Layout>
              {/* Route Setup */}
              <Route exact path="/" component={Main} />
              <Route path="/about" component={About} />
              <Route path='/projects' component={Projects} />

              {/* <Main></Main> */}
            </Layout>
          </div>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
