import React, { Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { AnimateOnChange } from 'react-animation';
import Tablet from '../../components/Cards/Tablet/Tablet';
import './Mackie.css';

const Mackie = (props) => {
  const [dialogOpen, setDialogOpen] = React.useState(true);

  const handleCloseDialog = () => {
    console.log('Close dialog');
    setDialogOpen(false);
  }
    return (
      <div class="main-container">
        <h2>The Options!</h2>
        <Tablet
          imageSrc="https://pisces.bbystatic.com/image2/BestBuy_US/images/products/5200/5200904_sd.jpg;maxHeight=640;maxWidth=550"
          pros={['Inexpensive', 'Many 3rd Party Accessories', 'Great for Easy Content']}
          cons={['Limited Storage', 'Not Very Powerful', 'Does Not Work ']}
          title="iPad"
          price="$449"
          consensus="Your run of the mill iPad is going to be exactly what you expect. 
          A tablet that you think will be great but in reality sits next to your bed for the next 5 years until you get a new tablet for Christmas.
          Sure, you'll still take it on every vacation because you feel like you're supposed to but really it will get about the same amount of use as the back door."/>
        <Tablet
          imageSrc="https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/ipad-air-select-wifi-spacegray-202203?wid=940&hei=1112&fmt=png-alpha&.v=1645066742664"
          pros={['Powerful M1 Chip', 'Many 3rd Party Accessories', 'Everything the iPad Pro Has']}
          cons={['Limited Storage', 'Not the Best Camera Features']}
          title="iPad Air M1"
          price="$599"
          consensus="This is the in-between iPad. It has the same chip as the iPad Pro with minimally less features.
          If the storage is not needed, there is almost no need to get the iPad Pro over the Air. 
          The only real gains from going with the Pro are storage and a few gimmicky camera features. 
          It is called 'Air' because that is exactly what Apple's marketing gurus are blowing out their ass."/>
        <Tablet
          imageSrc="https://www.att.com/idpassets/global/devices/tablets/apple/apple-ipad-pro-12-9-inch-2021/carousel/space-gray/6995C-2.png"
          pros={['Powerful M1 Chip', 'Lots of Storage', 'Many 3rd Party Accessories', 'Everything the Newest iPad Pro Has']}
          cons={['Expensive', 'Might be Overkill']}
          title="iPad Pro M1"
          price="$799"
          consensus="Obviously a very qualified tablet that has the same chip in it as the latest Macs.
          Can do probably everything you can imagine besides some of the most challenging computer only tasks. 
          The only real question is whether or not all of this computing power is needed for tasks like Netflix and reading soft porn kindle."/>
        <Tablet 
          imageSrc="https://www.apple.com/newsroom/images/product/ipad/standard/Apple-iPad-Pro-space-gray-2up-221018_big.jpg.large.jpg" 
          pros={['Extremely Powerful M2 Chip', 'Lots of Storage', 'Many 3rd Party Accessories', 'Face-tracking for Video', 'Run Almost Any Mac App']} 
          cons={['Expensive', '12.9" Might Be Big', 'Near Identical to M1 Generation', 'Pricey Accessories']} 
          title="iPad Pro M2" 
          price="$799+"
          consensus="This is probably the best tablet out there right now, however there is literally no difference between the previous M1 generation and this most recent generation.
          It would probably be a better deal to get a previous generation M1 iPad pro since there are likely more renewed or refurbished options. 
          Buy this iPad if you actually have artistic talent but have so much of daddies money that said talent turns into lines of snowy bliss every day that ends in y."/>
        <Tablet 
          imageSrc="https://image-us.samsung.com/SamsungUS/home/mobile/tablets/6132022/GalaxyTabS8_Combo_Graphite_1600x1200.jpg?$product-details-jpg$" 
          pros={['Freedom of Android Makes it Basically a Computer', 'Extendible Storage', 'Amazing Oled Screen']} 
          cons={['Non-In House Chip', 'Still Pricey', ]} 
          title="Samsung Tab S8/+" 
          price="$599+"
          consensus="At this point iPhones are for poor people and they just don't know it yet.
          Spec wise this would sit between the iPad Air and iPad Pro but performance is tough to judge against Apple's in house chips since they integrate with their software so well.
          When you buy the S8 every 100th person will recognize what you have and think 'wow that person knows what's up' instead of every other iPhone user seeing you with your iPad and thinking...
          nothing because they don't give a shit about you or your stupid iPad."/>
        <Tablet 
          imageSrc="https://image-us.samsung.com/SamsungUS/home/mobile/tablets/6132022/GalaxyTabS8Ultra_Combo_1600x1200.jpg?$product-details-jpg$" 
          pros={['Actually Just a Computer', 'Extendible Storage', 'Huge Oled Screen', 'Unbelievably Thin']} 
          cons={['Non-In House Chip', 'Really Really Pricey', 'Does Not Get Super Bright']} 
          title="Samsung Tab S8 Ultra" 
          price="$999+"
          consensus="If you had to pick 20 tablets out of a line up by looks, you would pick this one.
          At the same time picking 20 engagement rings out, you would pick the most expensive one.
          The stupidest part about this tablet is that it's actually just a laptop without a keyboard attached, which makes for a pretty dumb laptop.
          The screen is so good that if you use this to show your artistic friend their reflection while doing blow they may actually stop to look at themselves and consider their life choices."/>
         <Tablet 
          imageSrc="https://img-prod-cms-rt-microsoft-com.akamaized.net/cms/api/am/imageFileData/RE50ytn?ver=1e05&w=752&h=423&q=90&m=6&b=%23FFFFFFFF&o=f&aim=true" 
          pros={['Actually Just a Computer', 'Can Run Any Windows App', 'Will Have Ultra Long Support', 'Lots of 3rd Party Options']} 
          cons={['Not Really a Tablet', 'Really Really Pricey', 'Only Runs Windows', 'Shitty Screen']} 
          title="Microsoft Surface Pro" 
          price="$999+"
          consensus="If you had just said 'I want a new computer' I probably would have gotten you this. 
          That said, you would probably treat this the same way you treat your current chromebook so I guess I dodged a bullet there.
          Wanting to prove that you do productive things outside of work you will get a lot of use out of this the first two months, right up until it becomes a kitchen netflix device.
          This 'computer' (not tablet) would've been a huge hit at college 5 years ago but now it's kind of a wonder why any person gets one."/>
        {dialogOpen && <dialog id="favDialog" open={dialogOpen} onClick={handleCloseDialog}><div><h1>Congratulations!</h1><h3 className='free-tablet'>You have won a free tablet for being on the nice list!</h3><div><h3 className='tablet-options'>Check out your options!</h3><span className='dialog-span'>(because last time Peter chose so poorly)</span></div><button onClick={handleCloseDialog}>Close</button></div></dialog>}
      </div>
    )
}

export default Mackie;