import { useEffect, useState, useRef, useCallback } from "react"

// Move debounce function outside of the hook to avoid re-creation
const debounce = (func, wait = 25, immediate = true) => {
  let timeout;
  return function executedFunction(...args) {
    const context = this;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function useSticky() {
  const [isSticky, setSticky] = useState(false);
  const element = useRef(null);

  const handleScroll = useCallback(() => {
    if (element.current) {
      const shouldBeSticky = window.scrollY > element.current.getBoundingClientRect().top;
      setSticky(shouldBeSticky);
    }
  }, []);

  useEffect(() => {
    // Create a debounced version of handleScroll
    const debouncedHandleScroll = debounce(handleScroll);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return { isSticky, element };
}

export default useSticky;
