import React, { Component, useEffect } from 'react';

import Nav from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer';
import Main from '../../containers/Main/Main';
import useSticky from '../../hooks/useSticky/useSticky';

const Layout = (props) => {
  const showFooter = props.showFooter ?? true;
  const { isSticky, element } = useSticky();

  useEffect(() => {
    console.log('Reload')
  }, []);
  return (
    <div className="Layout">
      <div className="NavContainer" >
        <Nav sticky={isSticky} />
      </div>
      <div ref={element}>
        {props.children}
      </div>
      {showFooter && <div className="FooterContainer">
        <Footer />
      </div>}
    </div>
  )


}

export default Layout;