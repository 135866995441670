import React, { Component, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { AnimateOnChange } from 'react-animation';
import { useMediaQuery } from '@react-hook/media-query';

import './Main.css';
import '../../components/Cards/Standard/StandardCard';
import Headshot from '../../assets/pictures/Headshot.jpg';

// Component Imports
import Fadeto from '../../components/FadeTo/Fadeto';
import StandardCard from '../../components/Cards/Standard/StandardCard';
import Content from '../../components/Content/Content';

const WELCOME = ['Hi!', 'I\'m Peter', 'Welcome', 'bienvenue', 'bienvenida', 'willkommen', '欢迎', 'स्वागत हे', 'أهلا بك', 'добро пожаловать', 'bem-vinda', 'ようこそ', 'karibu', 'selamat datang', 'benvenuta','welkom', 'velkommen', 'tervetuloa'];

// class Main extends Component {
  const Main = ({ element }) => {
    const mediaMatches = useMediaQuery('only screen and (min-width: 992px)');
  // const welcome = ['Welcome', 'Hello', 'Goodbye', 'See ya'];
  // // const [welcome, setWelcome] = useState(['Welcome', 'Hello', 'Goodbye', 'See ya']);

  // constructor(props) {
  //   super(props);
  //   this.state = {welcome: [], count: 0};
  // }

  // componentDidMount() {
  //   // this.timerID = setInterval(() => this.incrementCount(), 5000);
  //   this.timerID = setInterval(() => {
  //     this.switchWelomeOut();
  //     setTimeout(this.switchWelcomeIn(), 1000);
  //   }, 5000);
  //   ReactDOM.render(<span className="WelcomeVisible">{this.state.welcome[this.state.count]}</span>, document.getElementById('Welcome'));
  // }

  // incrementCount() {
  //   console.log('Count is now', this.state.count);
  //   this.state.count = this.state.count < this.state.welcome.length - 1 ? this.state.count + 1 : 0;
  //   ReactDOM.render(<span>{this.state.welcome[this.state.count]}</span>, document.getElementById('Welcome'));
  // }

  // switchWelomeOut() {
  //   console.log('Welcome out');
  //   ReactDOM.render(<span className="WelcomeInvisible">{this.state.welcome[this.state.count]}</span>, document.getElementById('Welcome'));
  //   this.state.count = this.state.count < this.state.welcome.length - 1 ? this.state.count + 1 : 0;
  //   // setInterval(() => this.switchWelcomeIn(), 1000);
  //   // setTimeout(1000);
  //   // this.switchWelcomeIn();
  // }

  // switchWelcomeIn() {
  //   console.log('Welcome in');
  //   ReactDOM.render(<span className="WelcomeVisible">{this.state.welcome[this.state.count]}</span>, document.getElementById('Welcome'));
  // }

  // render () {
    return (
      <div id="MainContainer">
        {/* <div className="FirstName" id="Welcome"> */}
          {/* <AnimateOnChange> */}
            {mediaMatches &&
            <div className="FirstName" ref={element}>
              <Fadeto content={WELCOME}/>
            </div>
            }
          {/* <div className="FirstName" id="Welcome"> */}
            {/* <span>{this.state.welcome[this.state.count]}</span> */}
            {/* </div> */}
          {/* </AnimateOnChange> */}
        {/* <span id="Welcome" className="WelcomeTransition">{this.state.welcome}</span> */}
        {/* </div> */}
        {/* <div id="MainContent"> */}
        <Content>
          <div className="Main-section">
            <div className="Main-image-container">
              <img src={Headshot} />
            </div>
            <div className="Main-text-container">
              <p>Though I am a skier at heart, I primarily develop various software programs in my "free time". My main points of experience are in Front End development, specifically, with JavaScript ES6, React, Angular, and React Native. </p>
            </div>
          </div>
          <div className="Main-section">
            <div className="Main-text-container">
              <p>I am currently working as a freelance developer while starting a business focused on mobile app development. If you would like to work with me, or just chat about mutual interests, feel free to contact me at the information below.</p>
            </div>
            {mediaMatches &&
            <div className="Main-image-container-reverse"></div>
            }
          </div>
        {/* </div> */}
        </Content>
      </div>
    )
  // }
}

export default Main;